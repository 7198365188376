.rs-btn-sm {
    line-height: 17px;
}

.rs-picker-daterange-menu,
.rs-picker-date-menu
{
    z-index: 99999;
}

.MuiTable-root {
    border: 1px solid #ddd;
}

@media only screen and (max-width: 600px) {
    main {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .MuiDialog-paper {
        width: calc(100% - 10px) !important;
        margin: 0 !important;
    }

    .rs-picker-daterange-panel-show-one-calendar {
        width: 350px !important;
    }

    .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
        width: 250px !important;
    }

    .rs-picker-daterange-header {
        width: 350px !important;
    }
}
